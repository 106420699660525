import { getElementPosition, isElementInViewportY, raf, rafLoop } from './utils';

const headers = document.querySelectorAll('.section-header');

if (headers.length && raf) {
	const animateHeader = (header) => {
		const { y } = getElementPosition(header);
		const percent = Math.round((y / window.innerHeight) * 100);
		let opacity = (100 - percent) / 100;
		opacity = opacity < 0.1 ? 0.1 : opacity;
		opacity = opacity > 1 ? 1 : opacity;

		header.style.opacity = opacity;
	};

	const updateHeaders = () => {
		for (let i = 0; i < headers.length; i += 1) {
			const header = headers[i];

			if (isElementInViewportY(header)) {
				animateHeader(header);
			}
		}
	};

	rafLoop(updateHeaders);
}
