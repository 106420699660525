const forms = document.querySelectorAll('form');
const formsLength = forms.length;

if (formsLength) {
	const changeFieldState = function (e) {
		const field = e.target;
		const { parentNode } = field;

		if (field.value.length || field === document.activeElement) {
			parentNode.classList.add('active');
		} else {
			parentNode.classList.remove('active');
		}

		if (field.tagName === 'TEXTAREA') {
			field.style.height = 'auto';
			field.style.height = `${(field.scrollHeight + 3)}px`;
		}
	};

	for (let i = 0; i < formsLength; i += 1) {
		const form = forms[i];

		form.addEventListener('focus', changeFieldState, true);
		form.addEventListener('blur', changeFieldState, true);
		form.addEventListener('change', changeFieldState, true);
		form.addEventListener('keyup', changeFieldState, true);
	}
}
