import Siema from 'siema';

function initCarousel(wrap) {
	const setActivePagination = (index) => {
		const navWrap = wrap.nextSibling;
		const navLinks = navWrap.querySelectorAll('.nav');

		for (let i = 0; i < navLinks.length; i += 1) {
			const navLink = navLinks[i];

			navLink.classList.remove('active');

			if (i === index) {
				navLink.classList.add('active');
			}
		}
	};

	// Carousel instance
	const carousel = new Siema({
		selector: wrap,
		onChange() {
			const { currentSlide } = this;
			setActivePagination(currentSlide);
		},
	});

	// Trigger pagination creator
	carousel.addPagination();
}

// Pagination for carousel
Siema.prototype.addPagination = function () {
	const createPaginationButton = () => {
		const btn = document.createElement('button');
		btn.className = 'nav';
		const span = document.createElement('span');
		btn.appendChild(span);

		return btn;
	};

	const navWrap = document.createElement('div');
	navWrap.className = 'portfolio-carousel-nav nav-wrap';

	for (let i = 0; i < this.innerElements.length; i += 1) {
		const btn = createPaginationButton();
		if (i === 0) btn.classList.add('active');

		btn.addEventListener('click', () => this.goTo(i));
		navWrap.appendChild(btn);
	}

	this.selector.parentNode.insertBefore(navWrap, this.selector.nextSibling);
};

const siemas = document.querySelectorAll('.siema');

for (let i = 0; i < siemas.length; i += 1) {
	const selector = siemas[i];
	initCarousel(selector);
}
