// Mobile Navigation
const navToggle = document.getElementById('siteNavigationButton');

if (navToggle) {
	navToggle.addEventListener('click', () => {
		const nav = navToggle.getAttribute('aria-controls');
		const ariaExpanded = navToggle.getAttribute('aria-expadned') === 'true';

		navToggle.setAttribute('aria-expanded', !ariaExpanded);
		document.getElementById(nav).classList.toggle('is-open');
	});
}
