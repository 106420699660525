import { getElementPosition, isElementInViewportY, raf, rafLoop } from './utils';

const triangleWraps = document.querySelectorAll('.triangles-wrap');

if (triangleWraps.length && raf) {
	const animateTriangle = ({ el, index, y }) => {
		const rotate = (y * 0.25) + (index * 10);
		el.style.transform = `rotate(${rotate}deg)`;
	};

	const updateTriangles = () => {
		for (let i = 0; i < triangleWraps.length; i += 1) {
			const triangleWrap = triangleWraps[i];

			if (isElementInViewportY(triangleWrap)) {
				const { y } = getElementPosition(triangleWrap);

				const triangles = triangleWrap.querySelectorAll('.triangle');
				for (let j = 0; j < triangles.length; j += 1) {
					animateTriangle({ el: triangles[j], index: j, y });
				}
			}
		}
	};

	rafLoop(updateTriangles);
}
