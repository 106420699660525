import './navigation';
import './lazyLoad';
import './animationHeaders';
import './animationTriangles';
import './forms';
import './carousel';
import './pageNavigation';

const downArrow = document.getElementById('downArrow');
if (downArrow) {
	downArrow.addEventListener('click', (e) => {
		e.preventDefault();

		const content = document.getElementById('content');

		window.scrollBy({
			top: content.getBoundingClientRect().top,
			left: 0,
			behavior: 'smooth',
		});
	});
}
