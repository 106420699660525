class PageNavigation {
	constructor(nav) {
		this.nav = nav;

		this.bindEvents();
	}

	bindEvents() {
		const listItems = this.nav.querySelectorAll('.page-menu-item');

		for (let i = 0; i < listItems.length; i += 1) {
			const li = listItems[i];

			li.addEventListener('mouseover', () => this.setActive(li, true));
			li.addEventListener('mouseout', () => this.setActive(li, false));
		}
	}

	setActive(li, active) {
		const siblings = li.parentNode.children;

		[...siblings].forEach(element => element.classList.remove('is-active'));

		if (active) {
			li.classList.add('is-active');
		} else {
			[...siblings].forEach(element => (
				element.classList.contains('default-active') &&
				element.classList.add('is-active')
			));
		}
	}
}

const pageNavigations = document.querySelectorAll('.page-navigation');

if (pageNavigations.length) {
	for (let i = 0; i < pageNavigations.length; i += 1) {
		new PageNavigation(pageNavigations[i]);
	}
}
