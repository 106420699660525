export const getElementPosition = el => el.getBoundingClientRect();

export const isElementInViewportY = (el) => {
	const rect = el.getBoundingClientRect();

	return (
		(rect.top >= 0 || rect.bottom >= 0) &&
		rect.top <= window.innerHeight
	);
};

// Request animation frame
export const raf = window.requestAnimationFrame ||
	window.webkitRequestAnimationFrame ||
	window.mozRequestAnimationFrame ||
	window.msRequestAnimationFrame ||
	window.oRequestAnimationFrame;

export const rafLoop = (cb) => {
	let lastScrollTop = window.pageYOffset;

	const loop = () => {
		const scrollTop = window.pageYOffset;

		if (lastScrollTop !== scrollTop) {
			lastScrollTop = scrollTop;
			cb();
		}

		raf(loop);
	};

	cb();
	loop();
};
